// Generated by Framer (90417e1)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/XVUmpmPn1EPL0dzocT35/Ticker.js";
const TickerFonts = getFonts(Ticker);

const serializationHash = "framer-cdgnx"

const variantClassNames = {k7ZUwi1WU: "framer-v-1ii5nh7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "k7ZUwi1WU", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ii5nh7", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"k7ZUwi1WU"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><ComponentViewportProvider ><motion.div className={"framer-19h7o2g-container"} layoutDependency={layoutDependency} layoutId={"UUhLG6GAb-container"}><Ticker alignment={"center"} direction={"left"} fadeOptions={{fadeAlpha: 0, fadeContent: true, fadeInset: 0, fadeWidth: 25, overflow: false}} gap={10} height={"100%"} hoverFactor={1} id={"UUhLG6GAb"} layoutId={"UUhLG6GAb"} padding={10} paddingBottom={10} paddingLeft={10} paddingPerSide={false} paddingRight={10} paddingTop={10} sizingOptions={{heightType: true, widthType: true}} slots={[]} speed={100} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cdgnx.framer-17d1cm4, .framer-cdgnx .framer-17d1cm4 { display: block; }", ".framer-cdgnx.framer-1ii5nh7 { height: 200px; position: relative; width: 510px; }", ".framer-cdgnx .framer-19h7o2g-container { bottom: 0px; flex: none; left: calc(50.00000000000002% - 400px / 2); position: absolute; top: 0px; width: 400px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 510
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerewHVRIlU4: React.ComponentType<Props> = withCSS(Component, css, "framer-cdgnx") as typeof Component;
export default FramerewHVRIlU4;

FramerewHVRIlU4.displayName = "Carousel/Institutional";

FramerewHVRIlU4.defaultProps = {height: 200, width: 510};

addFonts(FramerewHVRIlU4, [{explicitInter: true, fonts: []}, ...TickerFonts], {supportsExplicitInterCodegen: true})